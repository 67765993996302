import { USER_ROLE_TITLES, DEV_ENVS, TEST_ENVS } from './constants';
import { format, isValid, parseISO, isSameDay } from 'date-fns';
import random from 'lodash/random';
import clone from 'lodash/clone';

// Tracking fields for various casting if needed in sort function below
const date_fields = ['expiration_date'];
const END_OF_DAYS = '9999-12-31T00:00:00';

/**
 * Sorts an array of data, according to a specified field.
 *
 * @param {String} order - The order of sorting, either "asc" or "desc".
 * @param {String} field - The name of the field to sort by.
 * @param {Array} data - The array containing objects to be sorted.
 *
 * @return {Array} The sorted array.
 */

export function sort(order, field, data) {
  // Keep track of fields that are dates and sortable
  let compare_dates = false;
  // If we have a date field, set the date to be dates
  if (date_fields.includes(field)) {
    compare_dates = true;
  }
  // Assume we are not sorting strings to start
  let compare_letters = false;
  let includes_accents = false;
  const regexPattern = /[àèìòùáéíóúýâêîôûãñõäëïöüÿÀÈÌÒÙÁÉÍÓÚÝÂÊÎÔÛÃÑÕÄËÏÖÜŸ]/;
  data.forEach(el => {
    if (typeof el[field] === 'string' && isNaN(el[field])) {
      compare_letters = true;
    }
    if (regexPattern.test(el[field])) {
      includes_accents = true;
    }
  });
  return data.sort((a, b) => {
    let comparison = 0;

    // check for null values first
    if (a[field] == null && b[field] == null) {
      comparison = 0;
    } else if (a[field] == null && b[field] !== null) {
      comparison = -1;
    } else if (a[field] !== null && b[field] == null) {
      comparison = 1;
    } else {
      // comparison if values are not null and dates
      if (compare_dates) {
        if (new Date(a[field]) > new Date(b[field])) {
          comparison = 1;
        } else if (new Date(b[field]) > new Date(a[field])) {
          comparison = -1;
        }
      }
      // comparison is between strings with accent letters
      else if (includes_accents) {
        comparison = a[field].localeCompare(b[field], 'en', {
          sensitivity: 'accent',
        });
      }
      // comparison is between strings which may be different casings
      else if (compare_letters) {
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          comparison = 1;
        } else if (b[field].toLowerCase() > a[field].toLowerCase()) {
          comparison = -1;
        }
      }
      // comparison if both values are not null
      else if (Number(a[field]) > Number(b[field])) {
        comparison = 1;
      } else if (Number(b[field]) > Number(a[field])) {
        comparison = -1;
      }
    }

    return order === 'asc' ? comparison * 1 : comparison * -1;
  });
}

/**
 * Sorts a users array, assumes you have a first or last name.
 *
 * @param {*} users - The users array to be sorted
 * @param {*} users_store - The users store is needed for details to sort by
 * @returns The sorted array with returned details from the users store
 */
export const sortUsers = (users, users_store) => {
  if (users == undefined) return [];
  const stored_users = users.map(user => {
    if (user.auxo_user_id) return users_store.getUserById(user.auxo_user_id);
    else if (user.username) return users_store.getUserByUsername(user.username);
    else return users_store.getUserByUsername(getUsername(user.email));
  });
  return stored_users.sort((a, b) => {
    const nameA = [a.first_name, a.last_name].filter(e => e).join(' ');
    const nameB = [b.first_name, b.last_name].filter(e => e).join(' ');
    return nameA.localeCompare(nameB);
  });
};

/**
 *  Format a number as a currency.
 *
 * @param {*} locale - What local should the returned number be
 * @param {*} decimals - Return decimals, true or false
 * @returns The formatted number
 */
export const formatCurrency = (locale = 'en-US', decimals = true) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimals ? 2 : 0,
    minimumFractionDigits: decimals ? 2 : 0,
  });
};

/**
 * Returns the username of a passed email
 * @param {*} email
 * @returns
 */
export const getUsername = email => {
  if (!email) return '';
  return email.substring(0, email.indexOf('@'));
};

/**
 * Formats a supplied date to the supplied dateFormat. Defaults to M/dd/yyyy.
 *
 * @param {*} date - the date to be formatted
 * @param {*} dateFormat - the format to be applied
 * @param {*} truncateTimezone - defaults to true, will truncate the timezone from the passed date
 * @returns
 */
export const formatDate = (
  date,
  dateFormat = 'M/dd/yyyy',
  truncateTimezone = true
) => {
  const type = typeof date;

  switch (type) {
    case 'object': {
      if (date === null || !isValid(date)) return '';
      return format(date, dateFormat);
    }
    case 'bigint':
    case 'number':
    case 'string': {
      const newDate = new parseISO(date);
      if (!isValid(newDate)) return '';

      if (truncateTimezone) {
        const newDateNoTimezone = new Date(
          date.slice(0, 10).replace(/-/g, '/')
        );
        if (!isValid(newDate)) return '';

        return format(newDateNoTimezone, dateFormat);
      }

      return format(newDate, dateFormat);
    }
    case 'boolean':
    case 'undefined':
    case 'function':
    case 'symbol':
    default:
      console.warn(
        `Unsupported date type supplied to formatDate function: ${type}`
      );
      return '';
  }
};

/**
 * A function to convert milliseconds to hh:mm:ss format.
 *
 * @param {*} milliseconds
 * @param {*} padStart
 * @returns
 */
export function formatMilliseconds(milliseconds, padStart) {
  function pad(num) {
    return `${num}`.padStart(2, '0');
  }
  let asSeconds = milliseconds / 1000;

  let hours = undefined;
  let minutes = Math.floor(asSeconds / 60);
  let seconds = Math.floor(asSeconds % 60);

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes %= 60;
  }

  return hours
    ? `${padStart ? pad(hours) : hours}:${pad(minutes)}:${pad(seconds)}`
    : `${padStart ? pad(minutes) : minutes}:${pad(seconds)}`;
}

/**
 * Function for calculating user time spent on a workitem
 *
 * If a user passes in a work_item_id as a param, it'll set the user metrics store
 * Otherwise, this will calculate the total time spent on that work item, sent it off
 * and then wipe the store.
 *
 * @param {*} user_store
 * @param {*} metric_store
 * @param {*} work_item_id
 * @returns null
 */

export function logUserAnalytics(users_store, metrics_store, work_item_id) {
  if (work_item_id && !metrics_store.currentTabID) {
    metrics_store.currentTabID = random(0, 1000000000000) + '#' + work_item_id;
    metrics_store.currentTabTimeStamp = Date.now();
    console.debug('set metrics store UUID...');
    console.debug(`ID set: ${metrics_store.currentTabID}`);
    console.debug(`Value set: ${metrics_store.currentTabTimeStamp}`);
  } else if (metrics_store.currentTabID && metrics_store.currentTabTimeStamp) {
    let initial_time = Number(metrics_store.currentTabTimeStamp);
    const time_spent = Date.now() - Number(initial_time);
    const auxo_user_id = users_store.activeUser.auxo_user_id;
    const work_item_id = Number(metrics_store.currentTabID.split('#')[1]);
    metrics_store.currentTabID = null;
    metrics_store.currentTabTimeStamp = null;
    console.debug('-----------------------------');
    console.debug(`Work item id: ${work_item_id}`);
    console.debug(`Total time spent on this ticket: ${time_spent}`);
    console.debug('-----------------------------');
    metrics_store.setTimeSpent(auxo_user_id, work_item_id, time_spent);
  }
}

/**
 * Formats a number with commas and specified decimal places
 *
 * @param {number} number - The number to be formatted
 * @param {number} decimalPlaces - The number of decimal places (optional)
 * @returns {string} The formatted number as a string
 */
export function formatNumber(number, decimalPlaces = 0) {
  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };
  return number && typeof number === 'string'
    ? parseFloat(number).toLocaleString(undefined, options)
    : number.toLocaleString(undefined, options);
}

/**
 * Returns the initials of a user given their first and last name.
 * @param {Object} user - The user object containing first_name and last_name properties.
 * @returns {string} The user's initials as a string.
 */
export function getUserInitials(user) {
  return [
    user?.first_name?.[0].toUpperCase(),
    user?.last_name?.[0].toUpperCase(),
  ].join('');
}

function isEnvironment(envList) {
  const client_prefix = process.env.VUE_APP_CLIENT_PREFIX;
  return envList.includes(client_prefix);
}

export function isDevelopment() {
  return isEnvironment(DEV_ENVS);
}

export function isTest() {
  return isEnvironment(TEST_ENVS);
}

/**
 * Returns dropdown options organized by a nested array of objects.
 * @param {Array} arr - The array of option objects to be organized
 * @returns {Array} The array of organized option objects
 */
export function sort_options_alphabetically(arr) {
  arr.sort((a, b) => a.level - b.level);
  const organized = [];
  arr.forEach(el => {
    if (el.level === 0) {
      if (el.child_ids) {
        // used for phase/status/reason
        organized.push({ ...el, children: [] });
      } else {
        // used for payer/plan
        organized.push({ ...el });
      }
    } else if (el.level === 1 && el.parent_ids) {
      // && el.parent_ids check is so this only executes for phase/status/reason
      organized.map(item => {
        if (item.value.rsp_id === el.parent_ids[0]) {
          let children = arr.filter(rsp =>
            el.child_ids.includes(rsp.value.rsp_id)
          );
          children.shift();
          item.children.push({ ...el, children: [...children] });
        }
      });
    }
  });

  const sort_logic = (a, b) => a.label.localeCompare(b.label);

  organized.sort(sort_logic);

  organized.forEach(el => {
    if (el.children.length) {
      el.children.sort(sort_logic);

      el.children.forEach(child => {
        if (child.children) child.children.sort(sort_logic);
      });
    }
  });

  return organized;
}

/**
 * Returns the array of options destructured into a one dimmentional array.
 * @param {Array} arr - The array of nested option objects to be destructured.
 * @returns {string} The destructured array of option objects.
 */
export function destructure_options(arr) {
  const result = [];

  arr.forEach(el => {
    result.push(el);
    if (el.children.length) {
      el.children.forEach(child => {
        result.push(child);
        if (child.children.length) {
          child.children.forEach(inner_child => {
            result.push(inner_child);
          });
        }
      });
    }
  });

  return result;
}

/**
 * a JS method to move an object to a specific index in an array,
 * and then adjust the rank attribute of elements indexed before or after the targeted
 * position depending on if they moved up in index, or down in index.
 *
 * @param {*} arr
 * @param {*} objToMove
 * @param {*} targetIndex
 * @returns
 */
export function moveAndAdjustRank(arr, objToMove, targetIndex) {
  const originalIndex = arr.indexOf(objToMove);

  if (originalIndex === -1) {
    throw new Error('Object not found in array.');
  }

  const targetRank = arr[targetIndex].rank;

  arr.splice(originalIndex, 1);
  arr.splice(targetIndex, 0, objToMove);

  objToMove.rank = targetRank;

  if (originalIndex < targetIndex) {
    for (let i = originalIndex; i < targetIndex; i++) {
      arr[i].rank--;
    }
  } else {
    for (let i = targetIndex + 1; i <= originalIndex; i++) {
      arr[i].rank++;
    }
  }

  return arr;
}

/**
 * Takes in an array of user role keys and returns a CSV string containing the full role names
 */
export function formatUserRoles(roles_arr) {
  return Object.keys(USER_ROLE_TITLES)
    .filter(role => roles_arr.includes(role))
    .map(role => USER_ROLE_TITLES[role])
    .join(', ');
}

/**
 * Sorts array of roles to follow correct ordering: ['REP', 'SUP', 'MGR', 'ADM']
 * @param {*} roleArr array of DB valued roles ex: ['REP', 'SUP']
 * @returns correctly sorted array based on access level
 */
export function sortUserRoles(roles) {
  const roles_correct_order = ['REP', 'SUP', 'MGR', 'ADM'];
  const orderMap = new Map(
    roles_correct_order.map((role, index) => [role, index])
  );
  return roles.sort((a, b) => orderMap.get(a) - orderMap.get(b));
}

/**
 * Gets the truncates the time and timezone safely off a date string.
 */
export const getDatePart = date => {
  if (!date) return null;
  const newDate = clone(date).slice(0, 10);
  return newDate;
};

/**
 * Helper for catching unauthorized and/or other errors
 */
export async function catchErrorAndToast(err, toast, toastMessage) {
  const errorBody = await err.response.json();
  if (err.response.status == 403) toast.error(toastMessage);
  else {
    toast.error(errorBody.message);
  }
  console.error(errorBody.message);
  return err.response ?? err;
}

//Checks to see if a date string that it is valid
//If it isn't, return null instead
export function nullDateIfNotValid(date) {
  return date && !isSameDay(parseISO(date.slice(0, 10)), parseISO(END_OF_DAYS))
    ? date
    : null;
}
