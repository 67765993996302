<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <p class="text-2xl text-primary font-bold">
        {{
          route.name === 'editWorklist' ? 'Edit worklist' : 'Add new worklist'
        }}
      </p>
      <p
        v-if="
          route.name === 'editWorklist' &&
          Object.keys(queryBuilder.worklist || {}).length
        "
        class="ml-[1em] text-sm text-primary-600 mt-1"
      >
        Last edited:
        {{
          formatDate(
            queryBuilder.worklist.mod_timest,
            "MM/dd/yy 'at' h:mma",
            false
          )
        }}
        by
        {{ users_store.getUserFullName(queryBuilder.worklist.mod_by) }}
      </p>
    </div>
    <div class="">
      <OutlinedButton class="mr-[1em] w-32 text-xs" @click="navigateBack">
        Cancel
      </OutlinedButton>

      <SolidButton
        class="w-32 text-xs"
        type="submit"
        :disabled="queryBuilder.disabledDuringAttempt"
        :class="
          queryBuilder.disabledDuringAttempt
            ? '!bg-[#8FA4B3] !border-[#8FA4B3]'
            : ''
        "
        @click="
          emit('setStartDate', tempStartDate);
          emit('setEndDate', tempEndDate);
        "
      >
        Save Worklist
      </SolidButton>
    </div>
  </div>
  <!-- Fields for new worklist -->
  <div class="bg-white drop-shadow-md p-3 my-[1em] text-sm relative z-[11]">
    <div class="flex justify-between items-end gap-2">
      <BaseInput
        v-model="data.work_list_name"
        :disabled="queryBuilder.defaultWorklist"
        :error="!data.work_list_name?.trim() && queryBuilder.attempt"
        wrapper-class="w-full flex-1"
        label="Worklist Name"
      />
      <GamePlanDatePicker
        key="start"
        label="Start Date (Optional)"
        :min-date="formatDate(new Date())"
        max-date=""
        class="w-full"
        placeholder="Select Start Date"
        :model-value="tempStartDate"
        unique-id-string="start"
        :disabled="checkStartDisabled()"
        @update="
          event => {
            tempStartDate = event;
            //edge case where the user selects a start date after the end date
            if (isBefore(tempEndDate, event)) {
              //date picker input clears but not actual value
              tempEndDate = null;
            }
          }
        "
      />
      <GamePlanDatePicker
        key="end"
        :label="data.is_priority ? 'End Date' : 'End Date (Optional)'"
        :min-date="
          isBefore(tempStartDate, new Date())
            ? formatDate(new Date())
            : tempStartDate || formatDate(new Date())
        "
        max-date=""
        class="w-full"
        placeholder="Select End Date"
        :model-value="tempEndDate"
        unique-id-string="end"
        :disabled="queryBuilder.defaultWorklist"
        :error="
          !data.tempEndDate &&
          queryBuilder.attempt &&
          data.is_priority &&
          !queryBuilder.defaultWorklist
        "
        @update="
          event => {
            tempEndDate = event;
          }
        "
      />
      <CheckBoxInput
        v-model="data.is_priority"
        :disabled="queryBuilder.defaultWorklist"
        class="flex-1 !p-0 !m-0 !mb-1.5"
        label="Priority Project"
      />
    </div>
    <div class="flex justify-between items-end gap-2">
      <BaseInput
        v-model="data.description"
        :disabled="queryBuilder.defaultWorklist"
        wrapper-class="flex-1"
        :error="
          !data.description?.trim() &&
          queryBuilder.attempt &&
          !queryBuilder.defaultWorklist
        "
        label="Description"
      />
      <div class="relative flex flex-col justify-end mt-1.5 flex-1">
        <label class="font-bold p-0.5 py-1 text-gray-500">Owner</label>
        <v-select
          placeholder=""
          :model-value="data.owner ? data.owner : null"
          :multiple="false"
          :clearable="false"
          :options="users_store.users.map(user => user.full_name)"
          @update:modelValue="node => (data.owner = node)"
        >
          <template #open-indicator="{ attributes }">
            <button
              v-if="data.owner"
              @click="
                e => {
                  e.preventDefault();
                  e.stopPropagation();
                  data.owner = null;
                  return false;
                }
              "
            >
              <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
            </button>

            <img
              :src="DownArrowSVG"
              v-bind="attributes"
              class="h-3 cursor-pointer ml-1"
            />
          </template>
        </v-select>
      </div>
      <div class="flex-1">
        <label class="font-bold p-0.5 py-1 text-gray-500">Assignees</label>
        <div class="flex justify-start pl-[0.5em]">
          <template
            v-for="user in data.assignees?.slice(0, 6)"
            :key="user?.auxo_user_id"
          >
            <div class="-ml-1.5">
              <UserPicture
                :user="users_store.getUserById(user.auxo_user_id)"
                :style="'bg-white'"
              />
            </div>
          </template>
          <div
            @mouseover="showExtraUsers = true"
            @mouseleave="showExtraUsers = false"
          >
            <Popper
              :hover="showExtraUsers"
              :show="showExtraUsers && data.assignees?.length > 6"
              placement="bottom-end"
            >
              <div
                v-if="data.assignees?.length > 6"
                class="-ml-1.5 rounded-full p-1 px-2 bg-white text-primary text-[18px] w-10 h-10 text-center text-monospace flex justify-center items-center border border-primary"
              >
                +{{ data.assignees?.length - 6 }}
              </div>
              <template #content>
                <div
                  ref="userList"
                  class="bg-white p-1 rounded border-solid border border-primary text-sm text-slate-800 max-h-52 overflow-auto"
                  @mouseleave="scrollToTop"
                >
                  <template
                    v-for="user in getGroupOfUsersNames(
                      data.assignees?.slice(6)
                    )"
                    :key="user.name"
                  >
                    <div>
                      {{ user.name + `${user.is_active ? '' : ' (inactive)'}` }}
                    </div>
                  </template>
                </div>
              </template>
            </Popper>
          </div>
          <div
            class="cursor-pointer font-extrabold bg-secondary rounded-full -ml-1.5 p-1 px-2 text-white text-[18px] w-10 h-10 text-center text-monospace flex justify-center items-center border border-secondary"
            @click="() => setAssigneeModal(!assigneeModal.value)"
          >
            <font-awesome-icon :icon="faPlus" />
          </div>
          <Transition>
            <div
              v-if="assigneeModal"
              id="assignee-modal"
              ref="assigneeModalRef"
              class="absolute mt-11 w-80 assignee-input"
            >
              <v-select
                :model-value="
                  data.assignees.map(user => {
                    return users_store.getUserById(user.auxo_user_id);
                  })
                "
                placeholder="Search assignees"
                :always-open="true"
                :close-on-select="false"
                multiple
                label="full_name"
                :options="users_store.activeUsers"
                @update:modelValue="
                  node => {
                    const previousValues = data.assignees;
                    const newValues = node;

                    const selection = node[node.length - 1];

                    const existence = data.assignees.find(
                      el => el.auxo_user_id == selection?.auxo_user_id
                    );

                    if (
                      !!existence &&
                      previousValues.length == newValues.length - 1
                    ) {
                      data.assignees = node
                        ?.map(user =>
                          users_store.getUserById(user.auxo_user_id)
                        )
                        .filter(
                          el => el.auxo_user_id !== existence.auxo_user_id
                        );
                    } else {
                      data.assignees = node?.map(user =>
                        users_store.getUserById(user.auxo_user_id)
                      );
                    }
                  }
                "
              >
                <template #option="option">
                  <div
                    :class="{
                      checked: isChecked(option) == true,
                    }"
                  >
                    <input
                      :id="`option-id-${option.auxo_user_id}`"
                      type="checkbox"
                      class="pointer-events-none"
                      :checked="isChecked(option) == true"
                      @click="
                        e => {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        }
                      "
                    />
                    {{ getOptionLabel(option) }}
                  </div>
                </template>
                <template #open-indicator="{ attributes }">
                  <button
                    v-if="data.assignees?.length"
                    @click="
                      e => {
                        e.preventDefault();
                        e.stopPropagation();
                        data.assignees = [];
                        return false;
                      }
                    "
                  >
                    <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
                  </button>
                  <img
                    :src="DownArrowSVG"
                    v-bind="attributes"
                    class="h-3 cursor-pointer ml-1"
                  />
                </template>
              </v-select>
            </div>
          </Transition>
        </div>
      </div>
      <div id="spacer" class="flex-1"></div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, watch } from 'vue';
import BaseInput from '../../components/forms/BaseInput.vue';
import CheckBoxInput from '@/components/forms/CheckBoxInput.vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import { useRoute } from 'vue-router';
import { formatDate } from '@/utils/helpers';
import { useUsersStore } from '@/stores/useUsers';
import GamePlanDatePicker from './GamePlanDatePicker.vue';
import Popper from 'vue3-popper';
import UserPicture from '@/components/UserPicture.vue';
import { storeToRefs } from 'pinia';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { onClickOutside } from '@vueuse/core';
import { isBefore } from 'date-fns';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';
import { useGamePlanStore } from '@/stores/useGamePlans';

const route = useRoute();

const queryBuilder = useQueryBuilderStore();
const users_store = useUsersStore();
const gameplanStore = useGamePlanStore();
const showExtraUsers = ref(false);
const assigneeModal = ref(false);
const assigneeModalRef = ref(null);
const tempStartDate = ref(null);
const tempEndDate = ref(null);

const setAssigneeModal = val => (assigneeModal.value = val);

const { getGroupOfUsersNames } = storeToRefs(users_store);

const data = reactive({
  ...queryBuilder.worklist,
});

tempStartDate.value = data.start_timest;
tempEndDate.value = data.end_timest;

// Is a checkbox checked
const isChecked = option => {
  return (
    data.assignees.findIndex(el => el.auxo_user_id == option.auxo_user_id) !==
    -1
  );
};

const getOptionLabel = option => {
  return (
    option.label ||
    option.full_name ||
    [option.first_name, option.last_name].join(' ')
  );
};

onClickOutside(
  assigneeModalRef,
  () => {
    assigneeModal.value = false;
  },
  {
    ignore: [],
  }
);

const checkStartDisabled = () => {
  const matchingWorklist = gameplanStore.original_worklists.filter(
    workList => workList.work_list_id === data.work_list_id
  )[0];
  if (!matchingWorklist) {
    //edge case for editing a newly created worklist
    return false;
  }
  if (data.start_timest === formatDate(new Date(), 'yyy-M-dd')) {
    if (matchingWorklist.start_timest !== data.start_timest) {
      return false;
    }
  }
  return (
    (data.start_timest &&
      isBefore(new Date(data.start_timest), new Date()) &&
      route.name === 'editWorklist') ||
    queryBuilder.defaultWorklist
  );
};

watch(
  () => queryBuilder.worklist,
  () => {
    const {
      work_list_name,
      description,
      owner,
      assignees,
      is_priority,
      start_timest,
      end_timest,
    } = queryBuilder.worklist;

    data.work_list_name = work_list_name;
    data.description = description;
    data.owner = owner;
    data.assignees = assignees;
    data.start_timest = start_timest;
    data.end_timest = end_timest;
    data.is_priority = is_priority || false;
  }
);

watch(data, () => {
  queryBuilder.updateWorklist(data);
});

defineProps({
  save: {
    type: Function,
    default: () => 1,
  },
  navigateBack: {
    type: Function,
    default: () => 1,
  },
});

const emit = defineEmits(['setStartDate', 'setEndDate']);
</script>
<style>
.assignee-input .vs__dropdown-option {
  padding: 0 !important;
}

.assignee-input .vs__dropdown-option > div {
  padding: 3px 20px;
}

.assignee-input .vs__dropdown-option > div.checked:hover {
  background: var(--vs-dropdown-option--deselect-bg);
  color: white;
}
</style>
