<template>
  <div class="flex-1">
    <label v-if="label" class="font-bold p-0.5 py-1 text-gray-500">{{
      props.label
    }}</label>
    <div class="mx-2">
      <div
        :id="`${props.uniqueIdString}-button`"
        :class="`${focused ? 'border-2 !border-black' : ''} ${
          props.error || formatError
            ? ' border-2 border-red-500'
            : 'border-gray-300'
        } ${
          props.disabled
            ? 'cursor-default pointer-events-none'
            : 'cursor-pointer'
        } ${props.class}`"
        class="border text-gray-900 text-sm rounded w-full flex gap-3 relative"
        @click="showDatePicker"
      >
        <div
          class="inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
        >
          <svg
            class="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
            />
          </svg>
        </div>
        <input
          type="text"
          :value="props.modelValue"
          :placeholder="props.placeholder"
          class="w-full h-full bg-transparent border-none outline-none cursor-text py-[7px] focus:!border-none"
          @keyup.enter="handleStringDateChange"
          @input="handleInput"
          ref="dateStringInput"
        />
        <div
          ref="dateInput"
          inline-datepicker
          :id="props.uniqueIdString"
          @changeDate="handleChangeDate"
          @click.stop="stopPropagation"
          class="absolute top-10 z-10"
          :class="`${isDatepickerVisible ? 'block' : 'hidden'} ${
            props.calendarClass
          }`"
          :data-date="props.modelValue"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { Datepicker } from 'flowbite';
import { format, parseISO, isBefore, isAfter, isEqual } from 'date-fns';
import { toast } from 'vue3-toastify';

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Select date',
  },
  minDate: {
    type: String,
    default: '',
  },
  maxDate: {
    type: String,
    default: '',
  },
  modelValue: {
    default: null,
    type: String,
  },
  //This should be unique across all datepickers
  uniqueIdString: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  error: {
    default: false,
    type: Boolean,
  },
  class: {
    default: '',
    type: String,
  },
  calendarClass: {
    default: '',
    type: String,
  },
});

const dateInput = ref(null);
const focused = ref(false);
const formatError = ref(false);
let datePickerInstance = null;
const isDatepickerVisible = ref(false);
const dateStringInput = ref(null);

const showDatePicker = () => {
  isDatepickerVisible.value = true;
  focused.value = true;
};

const hideDatePicker = () => {
  isDatepickerVisible.value = false;
  dateStringInput.value?.blur();
  focused.value = false;
};

const stopPropagation = event => {
  event.stopPropagation();
};

const emitAndClearErrors = update => {
  emit('update', update);
  formatError.value = false;
};

const showError = message => {
  toast.error(message);
  formatError.value = true;
};

const initializeDatePicker = () => {
  if (datePickerInstance) {
    datePickerInstance.destroy();
  }
  const options = {
    format: 'mm/dd/yyyy',
    orientation: 'bottom',
    buttons: true,
    minDate: props.disabled ? '' : props.minDate,
    maxDate: props.maxDate,
  };
  datePickerInstance = new Datepicker(dateInput.value, options);
  if (props.modelValue) {
    //if the date is in the form yyyy/mm/dd, new Date() makes it yesterday for some reason so we have to do this
    if (props.modelValue.split('-')[0].length == 4) {
      const parsedDate = parseISO(props.modelValue);
      datePickerInstance.setDate(parsedDate);
    } else {
      datePickerInstance.setDate(new Date(props.modelValue));
    }
  } else {
    //clear the date in the datepicker calendar
    dateInput.value['data-date'] = '';
  }
  //the buttons are a package deal in flowbite so we have to be sneaky and hide the today one :)
  const todayButton = document.querySelectorAll('.today-btn');
  todayButton.forEach(element => {
    element.style.display = 'none';
  });
  const clearButton = document.querySelectorAll('.clear-btn');
  clearButton.forEach(element => {
    element.style.width = '100%';
    element.style.margin = '0px';
  });
};

const processValidDate = date => {
  const parsedDate = new Date(date);

  if (props.maxDate && !isEqual(parsedDate, props.maxDate)) {
    if (!isBefore(parsedDate, props.maxDate)) {
      showError('Please enter a date before the max');
      return;
    }
  }

  if (props.minDate && !isEqual(parsedDate, props.minDate)) {
    if (!isAfter(parsedDate, props.minDate)) {
      showError('Please enter a date after the min');
      return;
    }
  }

  datePickerInstance.setDate(parsedDate);
  emit('update', date);
  formatError.value = false;
  dateStringInput.value.blur();
  focused.value = false;
  hideDatePicker();
};

// Watch for changes in the date props and update the datepicker instance
watch(
  () => [props.minDate, props.maxDate, props.modelValue],
  () => {
    initializeDatePicker();
  }
);

onMounted(() => {
  initializeDatePicker();
  document.addEventListener('click', handleEvent);
});

const handleEvent = event => {
  const datepickerElement = document.getElementById(props.uniqueIdString);
  const datePickerControls = document.querySelector('.datepicker');
  const datepickerButton = document.getElementById(
    `${props.uniqueIdString}-button`
  );

  const elementsToWatch = [
    datepickerElement,
    datepickerButton,
    datePickerControls,
  ].filter(e => e);
  let isClickInside = Array.from(elementsToWatch).some(el =>
    el?.contains(event.target)
  );
  if (!isClickInside) {
    if (isDatepickerVisible.value) {
      hideDatePicker();
    }
  }
};

const handleInput = event => {
  const date = event.target.value;
  const validDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  if (!date) {
    emitAndClearErrors(null);
  } else if (validDateRegex.test(date)) {
    handleStringDateChange(event);
    dateStringInput.value.blur();
  } else {
    focused.value = true;
  }
};

const handleStringDateChange = event => {
  let date = null;
  if (event.target.value) {
    date = event.target.value;
  } else if (event.detail.date) {
    date = format(event.detail.date, 'MM/dd/yyy');
  }

  const validDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  if (!date) {
    hideDatePicker();
    emitAndClearErrors(null);
  } else if (validDateRegex.test(date)) {
    processValidDate(date);
  } else {
    showError('Please enter a valid date');
  }
};

const handleChangeDate = event => {
  if (event.detail.date) {
    emit('update', format(event.detail.date, 'MM/dd/yyy'));
  } else {
    //we need it to be null in the backend
    emit('update', null);
  }
  hideDatePicker();
};

const emit = defineEmits(['update']);
</script>
