import { currentSession } from '@/utils/auth';
import { defineStore } from 'pinia';

// WriteRoles - These roles have access to click on a component or used to make something visible or hidden
// ReadRoles - They are currently setup but are not being used in the logic but were defined and here for future usage
export const useRbacStore = defineStore('rbac', {
  state: () => ({
    action_roles: {
      EDIT_GAMEPLAN: {
        readRoles: ['SUP'],
        writeRoles: ['MGR'],
      },
      OS_ADD_NEW_USER: {
        readRoles: [],
        writeRoles: ['ADM'],
      },
      REQUEST_WORK: {
        readRoles: [],
        writeRoles: ['REP', 'MGR'],
      },
      // Documents -> Appeal Letters
      GENERATE_DOCUMENTS: {
        readRoles: [],
        writeRoles: ['REP', 'ADM', 'MGR'],
      },
      // Documents -> Appeal Letters
      VIEW_DOCUMENTS: {
        readRoles: [],
        writeRoles: ['REP', 'ADM', 'MGR'],
      },
      WORKITEM_SEARCH: {
        readRoles: [],
        writeRoles: ['REP', 'ADM', 'MGR', 'SUP'],
      },
      ADD_NOTES: {
        readRoles: [],
        writeRoles: ['REP', 'ADM', 'MGR', 'SUP'],
      },
      CHECK_CLAIM_STATUS: {
        readRoles: ['SUP'],
        writeRoles: ['REP', 'ADM', 'MGR'],
      },
      CHAT_AI: {
        readRoles: ['SUP', 'REP', 'ADM', 'MGR'],
        writeRoles: ['REP', 'ADM', 'MGR'],
      },
      EDIT_USER_GOALS: {
        readRoles: [],
        writeRoles: ['MGR'],
      },
    },
    active_user_roles: null,
    // route_roles has route names and roles that have access
    route_roles: {
      LEAD: ['ADM', 'REP', 'SUP', 'MGR'],
      LEADID: ['REP', 'MGR', 'SUP', 'ADM'],
      DOCUMENT: ['MGR', 'REP', 'ADM'],
      NEWWORKLIST: ['MGR'],
      EDITWORKLIST: ['MGR'],
      GAMEPLAN: ['MGR', 'SUP'],
      USERS: ['SUP', 'MGR'],
      USERDETAIL: ['MGR', 'SUP'],
      STATS: ['MGR'],
      ADMIN: ['ADM'],
    },
    feature_flag: {
      CHAT_AI: {
        featureFlagEnvVar: 'VUE_APP_CHAT_AI_FF',
      },
      SIDEBAR_FLOWBITE: {
        featureFlagEnvVar: 'VUE_APP_SIDEBAR_FLOWBITE_FF',
      },
      GENERATE_APPEAL: {
        featureFlagEnvVar: 'VUE_APP_APPEAL_GENERATION_FF',
      },
      HOSTED_UI: {
        featureFlagEnvVar: 'VUE_APP_COGNITO_USE_HOSTED_UI',
      },
      HIDE_TRANSACTION_CODES: {
        featureFlagEnvVar: 'VUE_APP_HIDE_TRANSACTION_CODES_FF',
      },
      WORK_ITEM_VISIT_COLUMN: {
        featureFlagEnvVar: 'VUE_APP_WORK_ITEM_VISIT_COLUMN_FF',
      },
    },
  }),
  getters: {
    hasWriteRole: state => {
      return action => {
        const activeRoles =
          state.active_user_roles ||
          (() => {
            const token = currentSession();
            state.active_user_roles = token['cognito:groups'];
            return state.active_user_roles;
          })();
        const writeRoles = state.action_roles[action].writeRoles;
        const hasWriteAuthorization = activeRoles.some(role =>
          writeRoles.includes(role)
        );
        return hasWriteAuthorization;
      };
    },
    hasAccessToRoute: state => {
      return nav_name => {
        const activeRoles =
          state.active_user_roles ||
          (() => {
            const token = currentSession();
            state.active_user_roles = token['cognito:groups'];
            return state.active_user_roles;
          })();
        const routeRoles = state.route_roles[nav_name.toUpperCase()];
        const hasRouteAuthorization = activeRoles.some(role =>
          routeRoles.includes(role)
        );
        return hasRouteAuthorization;
      };
    },
    isFeatureFlagEnabled: state => {
      return feature_name => {
        const feature = state.feature_flag[feature_name].featureFlagEnvVar;
        return Boolean(parseInt(process.env[feature]) === 1);
      };
    },
  },
});
